
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { Operation } from '@/graphql/types';
import EventBus from '@/event-bus';
import VJsfForm from '@/components/commonComponents/VJsfForm.vue';
import {
  Schema,
  Model,
  Options,
  Valid,
} from '@/types/vjsf';
import { FetchPolicy } from 'apollo-client';
import Message from '@/components/mixins/Message.vue';

import { Metadata } from '@/types/ListTypes';

@Component({
  name: 'ListAddFields',
  components: {
    VJsfForm,
  },
  data: () => ({
    model: {} as Model,
    initialModel: {} as Model,
    schema: { type: 'object', properties: {} } as Schema,
    options: {} as Options,
    valid: null as Valid,
    metadata: {} as Metadata,
  }),
})
export default class ListAddFields extends Vue {
  @Prop()
  private readonly dossierId?: number;

  @Prop()
  private readonly blockId?: number;

  @Prop()
  private readonly navigationSlug?: string;

  @Prop()
  private readonly dossierTypeSlug?: string;

  @Prop()
  private readonly context?: string;

  @Prop()
  private readonly endpointName?: string;

  @Prop()
  private readonly onSaveNewItem?: (id: string) => void;

  name: string | undefined;

  valid = true;

  private loading = false;

  refresh = 0;

  mounted(): void {
    this.fetchData();
  }

  private validate(): boolean {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }

  private async fetchDataKeepModel(): Promise<void> {
    const currentModel = { ...this.$data.model };

    await this.fetchData('network-only');

    this.$data.model = currentModel;
  }

  private async fetchData(fetchPolicy: FetchPolicy = 'network-only'): Promise<void> {
    this.loading = true;

    return Promise.resolve().then(() => this.import())
      .then(({ QUERY: query }) => this.$apollo.query({
        query,
        fetchPolicy,
        variables: this.variables,
      }))
      .then((response) => {
        this.$data.schema = response.data.quickAddForm.schema;
        this.$data.options = { initialValidation: 'none', ...response.data.quickAddForm.options };
        this.$data.model = response.data.quickAddForm.model;
        this.$data.initialModel = { ...response.data.quickAddForm.model };
        this.$data.metadata = response.data.quickAddForm.metadata;
        this.valid = response.data.quickAddForm.valid;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
        this.$emit('rendered');
      });
  }

  private import(): Promise<Operation> {
    if (this.endpointName) {
      return import(`@/graphql/operations/${this.endpointName}`);
    }
    if (this.dossierId && this.blockId) {
      return import('@/graphql/operations/dossier-type-list-quick-add-form-by-block-id');
    }
    if (this.navigationSlug) {
      return import('@/graphql/operations/dossier-type-list-quick-add-form');
    }
    throw new Error('Either provide a tag, id or an endpoint name');
  }

  private get variables() {
    if (this.endpointName) {
      if (['dossier-tasks-quick-add-form', 'dossier-notes-quick-add-form', 'dossier-files-quick-add-form'].includes(this.endpointName)) {
        return {
          dossierId: this.dossierId,
          blockId: this.blockId,
          navigationSlug: this.navigationSlug,
          dossierTypeSlug: this.dossierTypeSlug,
        };
      }
      return {};
    }
    if (this.dossierId && this.blockId) {
      return {
        dossierId: this.dossierId,
        blockId: this.blockId,
        navigationSlug: this.navigationSlug,
        dossierTypeSlug: this.dossierTypeSlug,
        context: this.context,
      };
    }
    if (this.navigationSlug) {
      return {
        navigationSlug: this.navigationSlug,
        dossierTypeSlug: this.dossierTypeSlug ?? this.$route.params.dossierTypeSlug,
      };
    }

    throw new Error('Either provide a tag, id or an endpoint name');
  }

  @Watch('loading')
  private emitLoading() {
    this.$emit('update:loading', this.loading);
  }

  private resetForm(): void {
    this.$data.model = {};
    this.refresh += 1;
  }

  private submitForm(): void {
    if (!this.validate()) {
      return;
    }

    this.loading = true;

    this.import()
      .then(({ MUTATION: mutation }) => this.$apollo.mutate({
        mutation,
        variables: {
          ...this.variables,
          data: this.$data.model,
        },
      }))
      .then((response) => {
        this.$data.schema = response.data.quickAddForm.schema;
        this.$data.options = { ...response.data.quickAddForm.options };
        this.$data.model = { ...response.data.quickAddForm.model };
        this.$data.initialModel = { ...response.data.quickAddForm.model };
        this.valid = response.data.quickAddForm.valid;

        return this.$data.model.id as string;
      })
      .then((newId: string) => {
        if (this.valid) {
          this.$data.initialModel = { };
          Message.success(this.$t('add.notification'));
          if (this.onSaveNewItem) {
            this.onSaveNewItem(newId);
          }
          this.resetForm();
          EventBus.$emit('initializeForceRerender');
        } else {
          Message.error(this.$t('generic.error.occurred'));
        }
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}

